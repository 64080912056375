import { useEffect, useRef, useState } from "react";
import CandleStick from "./candlestickGraph";
import { debounce, throttle } from "../../utils/delayFunc";

const ArticleStats = ({ item, ScrollArticle, currency }) => {
    const [selectedTime, setSelectedTime] = useState("6h");

    const ThrottledWheelHandler = throttle((e) => {
        if (document.documentElement.clientWidth > 786) {
            if (e.deltaY > 0) {
                ScrollArticle("down");
            } else {
                ScrollArticle("up");
            }
        }
    }, 300);

    const DebouncedWheelHandler = debounce((e) => {
        ThrottledWheelHandler(e);
    }, 200);

    useEffect(() => {
        let elm = document.querySelector(".left-article-stats-container");

        elm.addEventListener("wheel", DebouncedWheelHandler);
        return () => {
            elm?.removeEventListener("wheel", DebouncedWheelHandler);
        };
    }, []);

    return (
        <div className="left-article-stats">
            <div style={{ marginBottom: "1.5rem" }}>
                <img
                    style={{ width: "7rem", height: "3.5rem", objectFit: "contain" }}
                    src={item?.thumbnail}
                    alt=""
                />
            </div>
            {item?.url && (
                <div
                    qontento-widget="true"
                    className="qx-heading qx-article version3"
                    data-url={item?.url}
                    style={{ minHeight: "42px" }}
                ></div>
            )}
            <a href={item?.url} target="_blank">
                <div
                    style={{
                        marginTop: "1.5rem",
                        fontSize: "1.75rem",
                        fontWeight: 700,
                    }}
                >
                    {item?.headline.length > 250
                        ? `${item?.headline.substring(0, 250)}...`
                        : item?.headline}
                </div>
            </a>
            <div style={{ marginTop: "1rem", fontSize: "1.125rem" }}>
                <span style={{ fontWeight: 600 }}>{item?.author ? item?.author : "Author"} </span>
            </div>
            <div className="article-stats-graph">
                <div className="article-stats-graph-outer">
                    <div className="flex-center" style={{ justifyContent: "space-between" }}>
                        <div style={{ fontSize: "1.75rem", fontWeight: 700 }}>Performance</div>
                        <div className="flex-center time-filter-container">
                            <button
                                onClick={() => {
                                    setSelectedTime("6h");
                                }}
                                className={
                                    selectedTime === "6h"
                                        ? "article-stats-graph-time time-6h active"
                                        : "article-stats-graph-time time-6h"
                                }
                            >
                                6H
                            </button>
                            <button
                                onClick={() => {
                                    setSelectedTime("1d");
                                }}
                                className={
                                    selectedTime === "1d"
                                        ? "article-stats-graph-time time-1d active"
                                        : "article-stats-graph-time time-1d"
                                }
                            >
                                1D
                            </button>
                            <button
                                onClick={() => {
                                    setSelectedTime("lt");
                                }}
                                className={
                                    selectedTime === "lt"
                                        ? "article-stats-graph-time time-lt active"
                                        : "article-stats-graph-time time-lt"
                                }
                            >
                                LT
                            </button>
                        </div>
                    </div>
                    <CandleStick selectedTime={selectedTime} item={item} currency={currency} />
                </div>
            </div>
            <div className="flex-center" style={{ justifyContent: "center", marginBottom: "1rem" }}>
                <button
                    onClick={() => {
                        ScrollArticle("down");
                    }}
                    id="next-article-button"
                >
                    <svg
                        viewBox="0 0 60 72"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="arrows"
                    >
                        <path className="a1" d="M0 0 L30 32 L60 0"></path>
                        <path className="a2" d="M0 20 L30 52 L60 20"></path>
                        <path className="a3" d="M0 40 L30 72 L60 40"></path>
                    </svg>
                </button>
            </div>
        </div>
    );
};
export default ArticleStats;
