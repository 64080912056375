import { createBrowserRouter } from "react-router-dom";
import SetPassword from "./SetPassword";
import VerifyAccount from "./VerifyAccount";
import MainPage from "../pages/main";
import HomePage from "./HomePage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage />,
    },
    { path: "/set-password", element: <SetPassword /> },
    { path: "/verify-account", element: <VerifyAccount /> },
]);

export default router;
