import { useEffect, useState } from "react";
import { currencySymbols } from "../../utils/constants";

const LeftTable = ({ currency, hideOnMobile, stats }) => {
    const [graphInteractions, setGraphInteractions] = useState(stats.graphInteractions);
    const [pageViews, setPageViews] = useState(stats.pageViews);
    const [highestContentPricedInDollars, setHighest] = useState(
        stats.highestContentPricedInDollars
    );
    const [readTime, setReadTime] = useState(stats.readTime);

    useEffect(() => {
        if (
            highestContentPricedInDollars === "" ||
            graphInteractions === "" ||
            pageViews === "" ||
            readTime === "" ||
            readTime === 0 ||
            highestContentPricedInDollars === 0 ||
            graphInteractions === 0 ||
            pageViews === 0 ||
            stats.forced
        ) {
            setHighest(stats.highestContentPricedInDollars);
            setPageViews(stats.pageViews);
            setGraphInteractions(stats.graphInteractions);
        }
    }, [stats]);

    useEffect(() => {
        let pageViewsTimeout;
        if (pageViews !== "") {
            pageViewsTimeout = setTimeout(() => {
                setPageViews(stats.pageViews);
            }, 6700);
        } else {
            setPageViews(stats.pageViews);
        }

        return () => {
            clearTimeout(pageViewsTimeout);
        };
    }, [pageViews, stats.pageViews]);
    useEffect(() => {
        let readTimeTimeout;
        if (readTime !== "") {
            readTimeTimeout = setTimeout(() => {
                setReadTime(stats.readTime);
            }, 6600);
        } else {
            setReadTime(stats.readTime);
        }

        return () => {
            clearTimeout(readTimeTimeout);
        };
    }, [readTime, stats.readTime]);

    useEffect(() => {
        let graphInteractionsTimeout;
        if (graphInteractions !== "") {
            graphInteractionsTimeout = setTimeout(() => {
                setGraphInteractions(stats.graphInteractions);
            }, 5700);
        }

        return () => {
            clearTimeout(graphInteractionsTimeout);
        };
    }, [graphInteractions, stats.graphInteractions]);

    useEffect(() => {
        let highestContentPricedInDollarsTimeout;
        if (highestContentPricedInDollars !== "") {
            highestContentPricedInDollarsTimeout = setTimeout(() => {
                if (highestContentPricedInDollars !== "") {
                    setHighest(stats.highestContentPricedInDollars);
                }
            }, 6800);
        }

        return () => {
            clearTimeout(highestContentPricedInDollarsTimeout);
        };
    }, [highestContentPricedInDollars, stats.highestContentPricedInDollars]);

    useEffect(() => {
        setReadTime(
            // stats.readTime === readTime && readTime > 0 ? readTime - 1 :
            stats.readTime
        );
        setGraphInteractions(
            // stats.graphInteractions === graphInteractions && graphInteractions > 0
            //     ? graphInteractions - 1
            //     :
            stats.graphInteractions
        );
        setHighest(stats.highestContentPricedInDollars);
        setPageViews(
            // stats.pageViews === pageViews && pageViews > 0 ? pageViews - 1 :
            stats.pageViews
        );
    }, [currency]);

    return (
        <div className="metric-table left-table">
            <div
                id="contenttable"
                className={hideOnMobile ? "table-container hideOnMobile" : "table-container"}
            >
                <div className="table">
                    <div className="table-title">CONTENT</div>
                    <div className="horizontalRule"></div>
                    <div className="table-rows-container">
                        <div className="table-row-box">
                            <div className="table-row">
                                <div className="table-label">
                                    <div className="table-label-text GIlabel">
                                        GRAPH INTERACTIONS
                                    </div>
                                </div>
                                <div className="table-value" id="graphInteractions">
                                    {graphInteractions}
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="table-label">
                                    <div className="table-label-text GIlabel">PAGE VIEWS</div>
                                </div>
                                <div className="table-value" id="pageViews">
                                    {pageViews}
                                </div>
                            </div>
                        </div>
                        <div className="table-row-box">
                            <div className="table-row">
                                <div className="table-label" style={{ display: "block" }}>
                                    <div className="table-label" style={{ marginRight: 0 }}>
                                        <div className="table-label-text">
                                            HIGHEST CONTENT PRICE
                                        </div>
                                    </div>
                                    <div className="this-month">(Last Month)</div>
                                </div>
                                <div className="table-value hasdollarwithodometer">
                                    <span className="selected-currency-symbol">
                                        {currencySymbols[currency]}
                                    </span>
                                    <span id="highestContentPricedInDollars">
                                        {highestContentPricedInDollars}
                                    </span>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="table-label">
                                    <div className="table-label-text GIlabel">READ TIME</div>
                                </div>
                                <div className="table-value no-wrap readtime">
                                    <span id="readTime">{readTime}</span>
                                    <span> min</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LeftTable;
