import { useEffect, useRef, useState } from "react";
import { currencySymbols } from "../../utils/constants";
import transition from "../../utils/transition";

const LeftHeader = ({ currency, setSelectedGraph, stats }) => {
    const [contentsPriced, setContentsPriced] = useState(stats.contentsPriced);
    const [usersEngaged, setUsersEngaged] = useState(stats.usersEngaged);
    const [contentConsumedValue, setContentConsumedValue] = useState(stats.contentConsumedValue);

    let contentsPricedTimeout = useRef(null);
    let usersEngagedTimeout = useRef(null);
    let contentConsumedValueTimeout = useRef(null);

    useEffect(() => {
        if (
            contentsPriced === "" ||
            usersEngaged === "" ||
            contentConsumedValue === "" ||
            contentsPriced === 0 ||
            usersEngaged === 0 ||
            contentConsumedValue === 0 ||
            stats.forced
        ) {
            setContentsPriced(stats.contentsPriced);
            setContentConsumedValue(stats.contentConsumedValue);
            setUsersEngaged(stats.usersEngaged);
        }
    }, [stats]);

    useEffect(() => {
        if (contentConsumedValue !== "") {
            contentConsumedValueTimeout.current = setTimeout(() => {
                setContentConsumedValue(stats.contentConsumedValue);
                transition("consumedContentLine", "consumedCircle");
            }, 16000);
        } else {
            setContentConsumedValue(stats.contentConsumedValue);
        }

        return () => {
            clearTimeout(contentConsumedValueTimeout.current);
        };
    }, [contentConsumedValue, stats.contentConsumedValue]);

    useEffect(() => {
        if (usersEngaged !== "") {
            usersEngagedTimeout.current = setTimeout(() => {
                setUsersEngaged(stats.usersEngaged);
                transition("usersEngagedLine", "usersCircle");
            }, 15000);
        }

        return () => {
            clearTimeout(usersEngagedTimeout.current);
        };
    }, [usersEngaged, stats.usersEngaged]);

    useEffect(() => {
        if (contentsPriced !== "") {
            contentsPricedTimeout.current = setTimeout(() => {
                if (contentsPriced !== "") {
                    setContentsPriced(stats.contentsPriced);
                    transition("contentPricedLine", "contentCircle");
                }
            }, 40000);
        }

        return () => {
            clearTimeout(contentsPricedTimeout.current);
        };
    }, [contentsPriced, stats.contentsPriced]);

    useEffect(() => {
        const mainMetrics = document.querySelectorAll(".main-metrics");

        for (let i = 0; i < mainMetrics.length; i++) {
            mainMetrics[i].addEventListener("mouseover", () => {
                const a = document.querySelectorAll(".main-metrics");
                for (let j = 0; j < a.length; j++) {
                    if (j !== i) {
                        a[j].style.opacity = 0.25;
                    } else {
                        a[j].style.opacity = 1;
                    }
                }
            });

            mainMetrics[i].addEventListener("mouseleave", () => {
                const a = document.querySelectorAll(".main-metrics");
                for (let i = 0; i < a.length; i++) {
                    a[i].style.opacity = 1;
                }
            });
        }
    }, []);

    useEffect(() => {
        setContentsPriced(
            // stats.contentsPriced === contentsPriced && contentsPriced > 0
            //     ? contentsPriced - 1
            //     :
            stats.contentsPriced
        );
        setContentConsumedValue(stats.contentConsumedValue);
        setUsersEngaged(
            // stats.usersEngaged === usersEngaged && usersEngaged > 0
            //     ? usersEngaged - 1
            //     :
            stats.usersEngaged
        );
    }, [currency]);

    return (
        <div className="header">
            <div className="header-left">
                <div
                    className="main-metrics"
                    onClick={() => {
                        setSelectedGraph("content priced");
                    }}
                >
                    <div id="contentsPriced" className="metric-value">
                        {contentsPriced}
                    </div>
                    <div className="metric-label">
                        <div
                            className="colorIndicator"
                            style={{ backgroundColor: "#d8be39" }}
                        ></div>
                        <div>CONTENT PRICED</div>
                    </div>
                </div>
                <div
                    className="main-metrics middle-metric"
                    onClick={() => {
                        setSelectedGraph("users engaged");
                    }}
                >
                    <div id="usersEngaged" className="metric-value">
                        {usersEngaged}
                    </div>
                    <div className="metric-label">
                        <div
                            className="colorIndicator"
                            style={{ backgroundColor: "#0085ff" }}
                        ></div>
                        <div>USERS ENGAGED</div>
                    </div>
                </div>
                <div
                    className="main-metrics"
                    onClick={() => {
                        setSelectedGraph("consumed content");
                    }}
                >
                    <div className="metric-value hasdollarwithodometer">
                        <span className="selected-currency-symbol">
                            {currencySymbols[currency]}
                        </span>
                        <span id="contentConsumedValue">{parseInt(contentConsumedValue)}</span>
                    </div>
                    <div className="metric-label ocw">
                        <div
                            className="colorIndicator"
                            style={{ backgroundColor: "#81ff00" }}
                        ></div>

                        <div>POTENTIAL CONTENT VALUE</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LeftHeader;
