const ArticleListItem = ({ item, selectedArticle, setSelectedArticle }) => {
    return (
        <div
            onClick={() => {
                setSelectedArticle(item);
            }}
            className={
                selectedArticle?.url === item?.url
                    ? "article-list-item selected"
                    : "article-list-item"
            }
        >
            <div className="detail-section">
                <img src={item?.thumbnail} />
                <div className="headline">
                    <span>
                        {document.documentElement.clientWidth > 786
                            ? item?.headline.length > 30
                                ? `${item?.headline.substring(0, 30)}...`
                                : item?.headline
                            : item?.headline.length > 18
                            ? `${item?.headline.substring(0, 18)}...`
                            : item?.headline}
                    </span>
                </div>
            </div>
            <div className="price-section">
                {item?.url && (
                    <div
                        qontento-widget="true"
                        data-url={item.url}
                        className="qx-heading version3"
                    ></div>
                )}
            </div>
        </div>
    );
};
export default ArticleListItem;
