const TnC = ({ setShowTnc, setAccessForm }) => {
    return (
        <div className="tnc-container">
            <div className="tnc-modal">
                <div className="tnc-heading">TERMS OF USE & PRIVACY POLICY</div>
                <div className="tnc-text-container">
                    <div className="tnc-text">
                        <div className="tnc-subheading">Welcome to Kunato.Ai!</div>
                        <br />
                        <b>This Terms of Use and Privacy Policy</b> outlines our practices with
                        respect to the collection, use, and disclosure of information we collect
                        through our website, as well as the terms that govern your use of our
                        website. By accessing or using our website, you agree to be bound by our
                        Terms of Use and Privacy Policy.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">1. Access to Kunato.Ai</div>
                        <br />
                        Access to Kunato.Ai is limited to registered users only. You may be required
                        to create an account with a unique username and password to access our
                        platform. You are responsible for maintaining the confidentiality of your
                        username and password and for all activities that occur under your account.
                        If you suspect any unauthorized use of your account, you should immediately
                        change your password and notify us.
                    </div>

                    <div className="tnc-text">
                        <div className="tnc-subheading">2. Non-Guarantee of Information</div>
                        <br />
                        The information provided on Kunato.Ai is for informational purposes only and
                        does not constitute an endorsement, recommendation, advertisement, or
                        solicitation of any kind. There is representation or warranty of any kind,
                        express or implied, regarding the accuracy, reliability, completeness, or
                        timeliness of the information provided on this website.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">
                            3. Confidentiality and Proprietary Rights
                        </div>
                        <br />
                        The information and materials on Kunato.Ai are confidential and intended
                        only for the use of registered users. All content and materials on our
                        website, including but not limited to text, software, graphics, images, and
                        trademarks, are the property of Kunato or its licensors and are protected by
                        intellectual property laws. You agree to keep all information and materials
                        confidential and not to disclose any information or materials to any third
                        party. You may not use any of these information and materials for any
                        purpose without our express written consent.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">4. Limitation of Liability</div>
                        <br />
                        In no event will Kunato, its partners, employees, consultants, agents, or
                        affiliates be liable for any damages, including without limitation direct or
                        indirect, special, incidental, or consequential damages, losses, or expenses
                        arising in connection with Kunato.Ai or use thereof or inability to use by
                        any party, or in connection with any failure of performance, error,
                        omission, interruption, defect, delay in operation or transmission, computer
                        virus, or line or system failure, even if Kunato, or representatives
                        thereof, are advised of the possibility of such damages, losses, or
                        expenses.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">5. Information Collection and Use</div>
                        <br />
                        Kunato may collect personal information that you voluntarily provide to us,
                        such as your name, email address, and other contact information. We may use
                        this information to respond to your requests, access the website, or for
                        other purposes related to your use of our website. We may also collect
                        information about your use of our website, such as your IP address, browser
                        type, operating system, and the pages you visit. This information is used to
                        improve the quality of our website and to provide more relevant information
                        to our visitors and users.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">6. Cookies and Similar Technologies</div>
                        <br />
                        Kunato may use cookies and similar technologies to provide a more
                        personalized experience for our visitors and users. A cookie is a small text
                        file that is stored on your device when you visit our website. Cookies can
                        be used to remember your preferences and to track your usage of our website.
                        Most web browsers allow you to control the use of cookies through their
                        settings.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">7. Information Sharing and Disclosure</div>
                        <br />
                        Kunato will not sell, rent, or lease your personal information to third
                        parties without your consent, except as necessary to provide you with the
                        information or services you request. We may also disclose your information
                        as required by law or to protect the rights and safety of Kunato and others.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">8. Security</div>
                        <br />
                        Kunato takes reasonable measures to protect the security of the information
                        we collect. However, no website or Internet transmission is completely
                        secure, and we cannot guarantee the security of your information.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">9. International Transfer</div>
                        <br />
                        Kunato may transfer your personal information to countries other than the
                        country in which it was collected. This may occur when, for example, our
                        servers are located in a different country than you. By using our website
                        and providing us with your personal information, you consent to the transfer
                        of your information to other countries.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">
                            10. Modifications to Terms of Use & Privacy Policy
                        </div>
                        <br />
                        Kunato reserves the right, at its sole discretion, to modify or replace this
                        Terms of Use and Privacy Policy at any time. What constitutes a material
                        change will be determined at our sole discretion.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">11. Governing Law</div>
                        <br />
                        These Terms of Use and Privacy Policy, and your use of Kunato.Ai shall be
                        governed by and construed in accordance with the laws of the State of
                        Delaware, United States of America, without giving effect to any principles
                        of conflicts of law.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">12. Dispute Resolution</div>
                        <br />
                        Any dispute arising out of or related to this Terms of Use and Privacy
                        Policy or the use of Kunato.Ai shall be resolved exclusively in the courts
                        of the State of Delaware, United States of America. You agree to submit to
                        the personal jurisdiction of such courts and waive any objections as to
                        inconvenient forum.
                    </div>
                    <div className="tnc-text">
                        <div className="tnc-subheading">13. Entire Agreement</div>
                        <br />
                        This Terms of Use and Privacy Policy, constitutes the entire agreement
                        between you and Kunato and govern your use of Kunato.Ai, superseding any
                        prior agreements between you and Kunato.
                    </div>
                    <i className="tnc-text">
                        If you have any questions regarding these Terms of Use and Privacy Policy or
                        your use of Kunato.Ai, please contact us at
                        <a
                            style={{ color: "#0085ff", fontWeight: 500 }}
                            href="mailto:support@kunato.io"
                            target="_blank"
                        >
                            support@kunato.io
                        </a>
                    </i>
                    <div className="tnc-text">Last updated: 9 February 2023</div>
                </div>
                <button
                    onClick={() => {
                        setAccessForm((prevState) => {
                            return { ...prevState, agreed: true };
                        });
                        setShowTnc(false);
                    }}
                >
                    I Agree
                </button>
            </div>
        </div>
    );
};
export default TnC;
