const RightTable = ({ hideOnMobile, stats }) => {
    return (
        <div className="metric-table">
            <div
                id="behaviortable"
                className={hideOnMobile ? "table-container hideOnMobile" : "table-container"}
            >
                <div className="table behaviour">
                    <div className="table-title">BEHAVIOR</div>
                    <div className="horizontalRule"></div>
                    <div className="table-rows-container">
                        <div className="table-row-box">
                            <div className="table-row">
                                <div
                                    className="table-label"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <div className="table-label-text">CONTENT COMPARISONS</div>
                                </div>
                                <div className="table-value" style={{ display: "flex" }}>
                                    <div className="maxSecContainer">
                                        <div
                                            style={{
                                                fontSize: "1rem",
                                                color: "#26c0e5",
                                            }}
                                        >
                                            <div>MAX</div>
                                            <div className="secContainer">/SEC</div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div id="maximumComparisions">
                                            {stats.maximumComparisions}
                                        </div>
                                        <div
                                            id="contentComparisionsPerSeconds"
                                            style={{ marginTop: "0.25rem" }}
                                        >
                                            {stats.contentComparisionsPerSeconds}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-row nnContainer">
                                <div className="table-label">
                                    <div className="table-label-text">
                                        NEURAL NETWORKS LEARNING RATE
                                    </div>
                                </div>
                                <div className="table-value">
                                    <div className="nnimage-container">
                                        <img src="/nngraph.gif" className="nnimage" />
                                    </div>
                                    <div className="nn-metric-container">
                                        <div className="nn-metric">
                                            <div>LOW</div>
                                            <div>0.0001</div>
                                        </div>
                                        <div className="nn-metric">
                                            <div>FAST</div>
                                            <div>0.01</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-row-box">
                            <div className="table-row">
                                <div className="table-label">
                                    <div className="table-label-text">PRICE CHANGE RATE</div>
                                </div>
                                <div className="table-value">
                                    <span id="priceChangeRate">{stats.priceChangeRate}</span>
                                    <span> sec</span>
                                </div>
                            </div>

                            <div className="table-row avgPriceDecay">
                                <div className="table-label">
                                    <div className="table-label-text">AVG. PRICE DECAY</div>
                                </div>
                                <div className="table-value">
                                    <div className="decayimage-container">
                                        <img
                                            src="/decayGraph.gif"
                                            alt="decaygraph"
                                            className="decayimage"
                                        />
                                    </div>
                                    <div className="decay-metric-container">
                                        <div>3220 min</div>
                                        <div>2880 min</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default RightTable;
