import PasswordForm from "./passwordForm";
import "./index.css";
import { useEffect, useState } from "react";

const SetPassword = () => {
    const [details, setDetails] = useState({
        name: "",
        countryCode: "",
        phone: "",
        email: "",
        company: "",
    });
    const { name, email, phone, countryCode, company } = details;
    const GetRequest = async () => {
        try {
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });
            const response = await fetch(
                `https://admin.kunato.ai/api/v1/request/get-request-details?requestId=${params.requestId}`,
                {
                    method: "get",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );

            const res = await response.json();
            if (res.ok) {
                setDetails({
                    name: res.data.requestDetails.name,
                    countryCode: res.data.requestDetails.countryCode,
                    phone: res.data.requestDetails.phone,
                    email: res.data.requestDetails.email,
                    company: res.data.requestDetails.company,
                });
                document.querySelector("#full-name").innerText = res.data.requestDetails.name;
                document.querySelector(
                    "#country-code"
                ).innerText = `+${res.data.requestDetails.countryCode}`;
                document.querySelector("#phone-number").innerText = res.data.requestDetails.phone;
                document.querySelector("#email").innerText = res.data.requestDetails.email;
                document.querySelector("#company").innerText = res.data.requestDetails.company;
            } else {
                window.location.href = "https://kunato.ai/";
            }
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        GetRequest();
    }, []);

    return (
        <div className="popup-container">
            <div className="popup-box">
                <div className="account-creation">Account Creation</div>
                <div className="form-container">
                    <div>
                        <div className="pre-filled-form" id="full-name">
                            {name}
                        </div>
                        <div className="flex-center" style={{ gap: "1rem" }}>
                            <div className="pre-filled-form" id="country-code">
                                {countryCode}
                            </div>
                            <div className="pre-filled-form" id="phone-number" style={{ flex: 1 }}>
                                {phone}
                            </div>
                        </div>
                        <div className="pre-filled-form" id="email">
                            {email}
                        </div>
                        <div className="pre-filled-form" id="company" style={{ marginBottom: 0 }}>
                            {company}
                        </div>
                    </div>
                    <div>
                        <PasswordForm />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SetPassword;
