import * as d3 from "d3";
import { currencySymbols } from "./constants";
function LineChart(
    data,
    {
        x = ([x]) => x, // given d in data, returns the (temporal) x-value
        y = ([, y]) => y, // given d in data, returns the (quantitative) y-value
        z = () => 1, // given d in data, returns the (categorical) z-value
        title, // given d in data, returns the title text
        defined, // for gaps in data
        curve = d3.curveNatural, // method of interpolation between points
        marginTop = 30, // top margin, in pixels
        marginRight = 20, // right margin, in pixels
        marginBottom = 30, // bottom margin, in pixels
        marginLeft = 18, // left margin, in pixels
        width = 640, // outer width, in pixels
        height = 400, // outer height, in pixels
        xType = d3.scaleUtc, // type of x-scale
        xDomain, // [xmin, xmax]
        xRange = [marginLeft, width - marginRight], // [left, right]
        yType = d3.scaleLinear, // type of y-scale
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat, // a format specifier string for the y-axis
        yLabel, // a label for the y-axis
        zDomain, // array of z-values
        color = "currentColor", // stroke color of line, as a constant or a function of *z*
        strokeLinecap, // stroke line cap of line
        strokeLinejoin, // stroke line join of line
        strokeWidth = "0.5rem", // stroke width of line
        strokeOpacity, // stroke opacity of line
        mixBlendMode = "normal", // blend mode of lines
        voronoi, // show a Voronoi overlay? (for debugging)
        currency,
        formatOption,
    } = {}
) {
    // Compute values.
    const X = d3.map(data, x);
    const Y = d3.map(data, y);
    const Z = d3.map(data, z);
    const O = d3.map(data, (d) => d);
    if (defined === undefined) defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
    const D = d3.map(data, defined);

    // Compute default domains, and unique the z-domain.
    if (xDomain === undefined)
        xDomain = [
            d3.min(X, (d) => {
                let date = new Date(d);
                date.setDate(date.getDate() - 10);
                return date;
            }),
            d3.max(X, (d) => {
                let date = new Date(d);
                date.setDate(date.getDate() + 10);
                return date;
            }),
        ];
    if (yDomain === undefined)
        yDomain = [
            d3.max(
                d3.map(
                    data.filter((item) => item.type !== "Consumed Content Value"),
                    y
                ),
                (d) => (typeof d === "string" ? +d : d)
            ) / -8,
            d3.max(
                d3.map(
                    data.filter((item) => item.type !== "Consumed Content Value"),
                    y
                ),
                (d) => (typeof d === "string" ? +d : d)
            ),
        ];
    if (zDomain === undefined) zDomain = Z;
    zDomain = new d3.InternSet(zDomain);

    const yDomain2 = [
        d3.max(
            d3.map(
                data.filter((item) => item.type === "Consumed Content Value"),
                y
            ),
            (d) => (typeof d === "string" ? +d : d)
        ) / -8,
        d3.max(
            d3.map(
                data.filter((item) => item.type === "Consumed Content Value"),
                y
            ),
            (d) => (typeof d === "string" ? +d : d)
        ),
    ];

    // Omit any data not present in the z-domain.
    const I = d3.range(X.length).filter((i) => zDomain.has(Z[i]));

    // Construct scales and axes.
    const xScale = xType(xDomain, xRange);
    const yScale = yType(yDomain, yRange);
    const yScale2 = yType(yDomain2, yRange);
    let xScalecopy = xScale;
    let yScalecopy = yScale;
    let yScale2copy = yScale2;
    const xAxis = d3
        .axisBottom(xScale)
        .ticks(Math.min(10, width / 70))
        .tickSizeOuter(0)
        .tickFormat(d3.timeFormat("%b %d"));
    const yAxis = d3.axisLeft(yScale).ticks(0).tickSizeOuter(0);
    // Compute titles.
    const T = title === undefined ? Z : title === null ? null : d3.map(data, title);

    // Construct a line generator.
    const line = d3
        .line()
        .defined((i) => D[i])
        .curve(curve)
        .x((i) => {
            return xScalecopy(X[i]);
        })
        .y((i) => {
            if (Z[i] === "Consumed Content Value") {
                return yScale2copy(Y[i]);
            } else {
                return yScalecopy(Y[i]);
            }
        });

    const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
        .style("-webkit-tap-highlight-color", "transparent")
        .on("pointerenter", pointerentered)
        .on("pointermove", pointermoved)
        .on("pointerleave", pointerleft)
        .on("touchstart", (event) => event.preventDefault());

    const hAxis = svg
        .append("g")
        .attr("color", "#434343")
        .attr("transform", `translate(0,${height - marginBottom})`)
        .call(xAxis);

    const vAxis = svg
        .append("g")
        .attr("color", "#434343")
        .attr("transform", `translate(${marginLeft},0)`)
        .call(yAxis);
    vAxis
        .append("text")
        .attr("fill", "#989898") //set the fill here
        .attr("font-size", "1rem")
        .attr("dy", () => {
            const rem = parseFloat(
                getComputedStyle(document.documentElement).fontSize.replace("px", "")
            );
            return -0.5 * rem;
        })
        .attr("dx", `-${height / 2.25}`)
        .attr("font-weight", 600)
        .attr("transform", "rotate(270)")
        .text("Millions");

    svg.append("linearGradient")
        .attr("id", "linear-gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", xScalecopy(X[0]))
        .attr("y1", 0)
        .attr("x2", xScalecopy(X[X.length - 1]))
        .attr("y2", 0)
        .selectAll("stop")
        .data([
            // background: linear-gradient(180deg, #D8BE39 20.07%, rgba(130, 191, 211, 0) 113.56%);

            {
                offset: -0.1356,
                color: "rgba(130, 191, 211, 0)", // color at 0%
            },
            {
                offset: 0.7993,
                color: "#D8BE39",
            },
        ])
        .enter()
        .append("stop")
        .attr("offset", function (d) {
            return d.offset;
        })
        .attr("stop-color", function (d) {
            return d.color;
        });

    svg.append("linearGradient")
        .attr("id", "linear-gradient2")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", xScalecopy(X[0]))
        .attr("y1", 0)
        .attr("x2", xScalecopy(X[X.length - 1]))
        .attr("y2", 0)
        .selectAll("stop")
        .data([
            //background: linear-gradient(180deg, #0085FF 25.95%, rgba(255, 214, 0, 0.4) 76.78%, rgba(27, 110, 173, 0.12) 94.27%);
            {
                offset: 0,
                color: "rgba(0,133,255,0.1)",
            },

            {
                offset: 1,
                color: "#0085ff",
            },
        ])
        .enter()
        .append("stop")
        .attr("offset", function (d) {
            return d.offset;
        })
        .attr("stop-color", function (d) {
            return d.color;
        });

    svg.append("linearGradient")
        .attr("id", "linear-gradient3")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", xScalecopy(X[0]))
        .attr("y1", 0)
        .attr("x2", xScalecopy(X[X.length - 1]))
        .attr("y2", 0)
        .selectAll("stop")
        .data([
            // background: linear-gradient(240.25deg, #81FF00 29.77%, rgba(27, 110, 173, 0.13) 102.09%);

            {
                offset: -0.0209,
                color: "rgba(27, 110, 173, 0.13)", // color at 0%
            },
            {
                offset: 0.7023,
                color: "#81FF00",
            },
        ])
        .enter()
        .append("stop")
        .attr("offset", function (d) {
            return d.offset;
        })
        .attr("stop-color", function (d) {
            return d.color;
        });

    svg.append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width - marginLeft - marginRight)
        .attr("height", height - marginBottom)
        .attr("transform", `translate(${marginLeft},${0})`);

    const container = svg
        .append("g")
        .attr("fill", "none")
        .attr("stroke", typeof color === "string" ? color : null)
        .attr("stroke-linecap", strokeLinecap)
        .attr("stroke-linejoin", strokeLinejoin)
        .attr("stroke-width", strokeWidth)
        .attr("stroke-opacity", strokeOpacity)
        .attr("clip-path", "url(#clip)")
        .selectAll("path")
        .data(d3.group(I, (i) => Z[i]))
        .join("path")
        .attr("stroke", ([z]) => {
            return z === "Content Priced"
                ? "url(#linear-gradient)"
                : z === "Users Engaged"
                ? "url(#linear-gradient2)"
                : z === "Consumed Content Value"
                ? "url(#linear-gradient3)"
                : "currentColor";
        })
        .attr("id", ([z]) => {
            return z === "Content Priced"
                ? "contentPricedLine"
                : z === "Users Engaged"
                ? "usersEngagedLine"
                : z === "Consumed Content Value"
                ? "consumedContentLine"
                : "";
        });
    const path = container.attr("d", ([, I]) => line(I));

    path.nodes().forEach((d, i) => {
        d3.select(d)
            .attr(
                "stroke-dasharray",
                path.nodes()[i].getTotalLength() + " " + path.nodes()[i].getTotalLength()
            )
            .attr("stroke-dashoffset", () => {
                return path.nodes()[i].getTotalLength();
            })
            .transition()
            .duration(2000)
            .attr("stroke-dashoffset", 0);

        setTimeout(() => {
            d3.select(d).attr("stroke-dasharray", null).attr("stroke-dashoffset", null);
        }, 3000);
    });

    let zoom = d3
        .zoom()
        .scaleExtent([1, 3.5])
        .translateExtent([
            [marginLeft, 0],
            [width, height],
        ])
        .on("zoom", zoomed);

    function zoomed(event) {
        d3.select("#contentCircle").attr("display", "none");
        d3.select("#usersCircle").attr("display", "none");
        d3.select("#consumedCircle").attr("display", "none");
        if (document.documentElement.clientWidth > 1024) {
            dot.attr("display", "none");
            document.querySelector(".tooltipContainer").style.display = "none";
        }
        yScalecopy = event.transform.rescaleY(yScale);
        yScale2copy = event.transform.rescaleY(yScale2);
        yAxis.scale(yScalecopy);
        vAxis.call(yAxis);
        xScalecopy = event.transform.rescaleX(xScale);
        xAxis.scale(xScalecopy);
        hAxis.call(xAxis);
        path.attr("d", ([, I]) => line(I));
    }

    svg.call(zoom);

    const circleContainer = svg.append("g").attr("clipPath", "url(#clip)");

    const dot = circleContainer.append("g").attr("display", "none");

    dot.append("circle").attr("r", "0.5rem");

    const Tooltip = d3.select("#graph").append("div").attr("class", "tooltipContainer");

    Tooltip.append("div").attr("class", "tooltipValue");
    Tooltip.append("div").attr("class", "tooltipName");

    function pointermoved(event) {
        dot.attr("display", null);

        const [xm, ym] = d3.pointer(event);
        const i = d3.least(I, (i) =>
            Math.hypot(
                xScalecopy(X[i]) - xm,
                Z[i] === "Consumed Content Value" ? yScale2copy(Y[i]) - ym : yScalecopy(Y[i]) - ym
            )
        ); // closest point
        path.style("stroke-opacity", ([z]) => (Z[i] === z ? null : "0.15"))
            .filter(([z]) => Z[i] === z)
            .raise();
        dot.attr(
            "transform",
            `translate(${xScalecopy(X[i])},${
                Z[i] === "Consumed Content Value" ? yScale2copy(Y[i]) : yScalecopy(Y[i])
            })`
        );
        dot.attr("fill", () =>
            Z[i] === "Content Priced"
                ? "#D8BE39"
                : Z[i] === "Users Engaged"
                ? "#5aa6ff"
                : Z[i] === "Consumed Content Value"
                ? "#81ff00"
                : "currentColor"
        );

        if (Z[i] === "Content Priced") {
            FadeMetrics("contentsPriced");
            document.querySelector("#contentCircle").setAttribute("fill", "rgba(216,190,57,1)");
            document.querySelector("#usersCircle").setAttribute("fill", "rgba(90,166,255,0.25)");
            document.querySelector("#consumedCircle").setAttribute("fill", "rgba(129,255,0,0.25)");
        }
        if (Z[i] === "Users Engaged") {
            FadeMetrics("usersEngaged");
            document.querySelector("#contentCircle").setAttribute("fill", "rgba(216,190,57,0.25)");
            document.querySelector("#usersCircle").setAttribute("fill", "rgba(90,166,255,1)");
            document.querySelector("#consumedCircle").setAttribute("fill", "rgba(129,255,0,0.25)");
        }
        if (Z[i] === "Consumed Content Value") {
            FadeMetrics("contentConsumedValue");
            document.querySelector("#contentCircle").setAttribute("fill", "rgba(216,190,57,0.25)");
            document.querySelector("#usersCircle").setAttribute("fill", "rgba(90,166,255,0.25)");
            document.querySelector("#consumedCircle").setAttribute("fill", "rgba(129,255,0,1)");
        }

        if (T) {
            Z[i] === "Consumed Content Value"
                ? (document.querySelector(".tooltipValue").innerHTML = `<span>&rlm;${
                      currencySymbols[currency]
                  }&lrm; </span><span>${Math.trunc(Y[i])?.toLocaleString(formatOption)}</span>`)
                : (document.querySelector(".tooltipValue").innerHTML = `<span>${Y[
                      i
                  ]?.toLocaleString(formatOption)}</span>`);

            let options = { year: "numeric", month: "short", day: "numeric" };
            document.querySelector(".tooltipName").innerText = `${new Date(
                X[i]
            )?.toLocaleDateString("en-UK", options)}`;
        }
        document.querySelector(".tooltipContainer").style.display = "flex";
        const boundingRect = document.querySelector(".graphOuter").getBoundingClientRect();
        if (document.documentElement.clientWidth < 1024) {
            if (boundingRect.right - xScalecopy(X[i]) > 150) {
                document.querySelector(".tooltipContainer").style.left = `calc(${xScalecopy(
                    X[i]
                )}px + 1rem)`;
            } else {
                document.querySelector(".tooltipContainer").style.left = `calc(${xScalecopy(
                    X[i]
                )}px - 10rem)`;
            }
            if ((Z[i] === "Consumed Content Value" ? yScale2copy(Y[i]) : yScalecopy(Y[i])) > 90) {
                document.querySelector(".tooltipContainer").style.top = `calc(${
                    Z[i] === "Consumed Content Value" ? yScale2copy(Y[i]) : yScalecopy(Y[i])
                }px - 7rem)`;
            } else {
                document.querySelector(".tooltipContainer").style.top = `calc(${
                    Z[i] === "Consumed Content Value" ? yScale2copy(Y[i]) : yScalecopy(Y[i])
                }px)`;
            }
        } else {
            if (boundingRect.right - xScalecopy(X[i]) > 180) {
                document.querySelector(".tooltipContainer").style.left = `calc(${xScalecopy(
                    X[i]
                )}px + 1rem)`;
            } else {
                document.querySelector(".tooltipContainer").style.left = `calc(${xScalecopy(
                    X[i]
                )}px - 6rem)`;
            }
            document.querySelector(".tooltipContainer").style.top = `calc(${
                Z[i] === "Consumed Content Value" ? yScale2copy(Y[i]) : yScalecopy(Y[i])
            }px + 1rem)`;
        }

        svg.property("value", O[i]).dispatch("input", { bubbles: true });
    }

    function pointerentered() {
        path.style("mix-blend-mode", null).style("stroke-opacity", "1").style("opacity", "1");
        dot.attr("display", null);
        ResetMetrics();
    }

    function pointerleft(event) {
        if (!document.querySelector(".tooltipContainer").contains(event.relatedTarget)) {
            path.style("mix-blend-mode", mixBlendMode).style("stroke-opacity", "1");
            dot.attr("display", "none");
            ResetMetrics();
            svg.node().value = null;
            svg.dispatch("input", { bubbles: true });
            document.querySelector(".tooltipContainer").style.display = "none";
            document.querySelector("#contentCircle").setAttribute("fill", "rgba(216,190,57,1)");
            document.querySelector("#usersCircle").setAttribute("fill", "rgba(90,166,255,1)");
            document.querySelector("#consumedCircle").setAttribute("fill", "rgba(129,255,0,1)");
        }
    }

    circleContainer
        .append("circle")
        .attr("r", "0.5rem")
        .attr("id", "contentCircle")
        .attr("fill", "rgba(216,190,57,1)")
        .attr("opacity", 0);

    circleContainer
        .append("circle")
        .attr("r", "0.5rem")
        .attr("id", "usersCircle")
        .attr("fill", "rgba(90,166,255,1)")
        .attr("opacity", 0);

    circleContainer
        .append("circle")
        .attr("r", "0.5rem")
        .attr("id", "consumedCircle")
        .attr("fill", "rgba(129,255,0,1)")
        .attr("opacity", 0);

    document.querySelector("#graph").addEventListener("mouseleave", pointerleft);

    return Object.assign(svg.node(), { value: null });
}
const FadeMetrics = (id) => {
    const elms = document.querySelectorAll(".main-metrics");
    for (let i = 0; i < elms.length; i++) {
        const elm = elms[i];
        if (elm.querySelector(`#${id}`)) {
            elm.style.opacity = "1";
        } else {
            elm.style.opacity = "0.25";
        }
    }
};
const ResetMetrics = () => {
    const elms = document.querySelectorAll(".main-metrics");
    for (let i = 0; i < elms.length; i++) {
        const elm = elms[i];
        elm.style.opacity = "1";
    }
};

export default LineChart;
