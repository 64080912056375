import { useEffect, useRef, useState } from "react";

import LeftSection from "./leftSection";
import RightSection from "./rightSection";
import { refreshToken } from "../../utils/refreshToken";

const MainSection = ({
    currency,
    setCurrency,
    displayedCurrency,
    graphData,
    formatOption,
    stats,
    addOdometerClass,
}) => {
    const [selectedGraph, setSelectedGraph] = useState("users engaged");

    const [statsVersion, setStatsVersion] = useState("pricing-engine");
    const [latestArticles, setLatestArticles] = useState([]);

    const selectedArticleRef = useRef(null);
    const [selectedArticle, setSelectedArticle] = useState(selectedArticleRef.current);
    let latestArticleTimeout = useRef(null);
    let dataInit = useRef(null);

    const fetchLatestArticles = async () => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            try {
                const response = await fetch("https://search.kunato.ai/api/search/latest", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
                const res = await response.json();

                if (res.ok) {
                    setLatestArticles([]);
                    let data = [];
                    for (let i = 0; i < res.articles.length; i++) {
                        data.push({
                            headline: res.articles[i].title,
                            url: res.articles[i].url,
                            publication: "Publication",
                            thumbnail: res.articles[i].thumbnail,
                            author: res.articles[i].author,
                        });
                    }
                    setLatestArticles(data);
                    if (!selectedArticleRef.current) {
                        setSelectedArticle(data[0]);
                    }
                } else if (response.status === 401) {
                    const refreshSuccess = await refreshToken();
                    if (refreshSuccess) {
                        await fetchLatestArticles();
                    } else {
                        window.location.reload();
                    }
                } else {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("refreshToken");
                    window.location.reload();
                }
                clearTimeout(latestArticleTimeout.current);
                latestArticleTimeout.current = setTimeout(async () => {
                    await fetchLatestArticles();
                }, 30000);
            } catch (err) {
                console.log(err);
                clearTimeout(latestArticleTimeout.current);
                latestArticleTimeout.current = setTimeout(async () => {
                    await fetchLatestArticles();
                }, 30000);
            }
        } else {
            window.location.reload();
        }
    };

    const ScrollArticle = (direction) => {
        if (selectedArticle?.url) {
            if (direction === "up") {
                const currentIndex = latestArticles.findIndex(
                    (item) => item?.url === selectedArticle?.url
                );
                if (currentIndex > 0) {
                    setSelectedArticle(latestArticles[currentIndex - 1]);
                } else {
                    setSelectedArticle(latestArticles[0]);
                }
            } else if (direction === "down") {
                const currentIndex = latestArticles.findIndex(
                    (item) => item?.url === selectedArticle?.url
                );
                if (currentIndex < latestArticles.length - 1) {
                    setSelectedArticle(latestArticles[currentIndex + 1]);
                } else if (currentIndex < 0) {
                    setSelectedArticle(latestArticles[0]);
                }
            }
        }
    };

    useEffect(() => {
        if (!dataInit.current) {
            dataInit.current = true;
            fetchLatestArticles();
        }
        return () => {
            clearTimeout(latestArticleTimeout.current);
        };
    }, []);
    useEffect(() => {
        selectedArticleRef.current = selectedArticle;
    }, [selectedArticle]);

    useEffect(() => {
        if (stats.usersEngaged !== 0) {
            addOdometerClass();
        }
    }, [statsVersion]);

    return (
        <div className="main-section">
            <LeftSection
                currency={displayedCurrency}
                graphData={graphData}
                selectedGraph={selectedGraph}
                setSelectedGraph={setSelectedGraph}
                formatOption={formatOption}
                statsVersion={statsVersion}
                selectedArticle={selectedArticle}
                ScrollArticle={ScrollArticle}
                stats={stats}
            />
            <RightSection
                stats={stats}
                currency={currency}
                setCurrency={setCurrency}
                statsVersion={statsVersion}
                setStatsVersion={setStatsVersion}
                latestArticles={latestArticles}
                selectedArticle={selectedArticle}
                setSelectedArticle={setSelectedArticle}
            />
        </div>
    );
};
export default MainSection;
