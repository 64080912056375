const PricingEngine = ({ accuracy }) => {
    return (
        <div className="pricing-engine-stats">
            <div className="stats-section-text">
                Quantify the market value of content based on numerous trends, demands, quality,
                interest, impact, behavior, uniqueness, genre, event, and 100’s of other signals.
            </div>
            <div className="hiddenlayers">Hidden Layers</div>

            <div className="stats-row">
                <div className="stat-label">
                    POLICY
                    <br />
                    GENERATOR
                </div>
                <div className="stat-value-container">
                    <div>
                        <span className="n-metric">N</span>
                        <span className="n-metric-sm">3</span>
                    </div>
                    <div className="dot-matrix-container" style={{ position: "relative" }}>
                        <div className="dot active"></div>
                        <div className="dot active"></div>
                        <div className="dot active"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="connector">
                            <svg viewBox="0 0 9 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0V22.56H8V48" stroke="#26C0E5" strokeWidth="2" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stats-row" style={{ marginTop: "1.6rem" }}>
                <div className="stat-label">POLICY CLASS</div>
                <div className="stat-value-container">
                    <div>
                        <span className="n-metric">N</span>
                        <span className="n-metric-sm">2</span>
                    </div>
                    <div className="dot-matrix-container">
                        <div className="dot active"></div>
                        <div className="dot active"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </div>
            </div>
            <div className="stats-row" style={{ marginTop: "2rem" }}>
                <div className="stat-label">
                    POLICY
                    <br />
                    BEHAVIOR
                </div>
                <div className="stat-value-container">
                    <div className="grey">
                        <span className="n-metric">N</span>
                        <span className="n-metric-sm">0</span>
                    </div>
                    <div>
                        <div className="dot-matrix-container">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <div className="dot-matrix-container" style={{ marginTop: "0.5625rem" }}>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <div className="dot-matrix-container" style={{ marginTop: "0.5625rem" }}>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <div className="dot-matrix-container" style={{ marginTop: "0.5625rem" }}>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stats-row" style={{ marginTop: "1.5rem" }}>
                <div className="stat-label">
                    ADAPTIVE
                    <br />
                    POLICY
                </div>
                <div className="stat-value-container">
                    <div className="grey">
                        <span className="n-metric">N</span>
                        <span className="n-metric-sm">0</span>
                    </div>
                    <div className="dot-matrix-container">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <hr
                    style={{
                        width: "80%",
                        margin: "1.75rem 0rem",
                        borderColor: "rgba(255, 255, 255, 0)",
                        backgroundColor: "rgba(255, 255, 255, 0)",
                    }}
                />
            </div>
            <div className="accuracy-container">
                <div className="accuracy-percentage">
                    <span id="bigAccuracy">
                        {`${`${accuracy}`.substring(0, `${accuracy}`.indexOf(",") + 3)}%`}
                    </span>
                    <div className="precisionAccuracy">
                        <span id="accuracy">{accuracy}</span>%
                    </div>
                </div>
                <span className="accuracy-label"> ACCURACY</span>
            </div>
            <div className="uptime-container">
                <div className="uptime">
                    <span>UPTIME </span>99.96%
                </div>
            </div>
        </div>
    );
};
export default PricingEngine;
