export const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken) {
        try {
            const response = await fetch(
                `https://beta.kunato.ai/api/auth/v1/sso/users/refresh-jwt`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        refreshToken: refreshToken,
                    }),
                }
            );
            const res = await response.json();
            if (res.ok) {
                localStorage.setItem("accessToken", res.data.accessToken);
                localStorage.setItem("refreshToken", res.data.refreshToken);
                return true;
            } else {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                return false;
            }
        } catch (err) {
            console.log(err);
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            return false;
        }
    }
    localStorage.removeItem("accessToken");
    return false;
};
