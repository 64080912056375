const ErrorState = () => {
    return (
        <div className="container-404" style={{ backgroundImage: "url('/bg404.png')" }}>
            <div className="heading">
                <a href="/">
                    {" "}
                    <span>Kunato.Ai</span>
                </a>
            </div>
            <div className="main-404">
                <div>
                    <div className="graphic">
                        <img src="/error.gif" alt="" />
                    </div>
                    <div className="text-404">
                        <div className="headline">
                            <span>Be Right Back</span>
                        </div>
                        <div className="subtext">
                            <span>Updating this page with some new ideas.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-404">
                <span>© {new Date().getFullYear()} Kunato All rights reserved</span>
            </div>
        </div>
    );
};
export default ErrorState;
