import { useRef, useState } from "react";
import { currencies, currencySymbols } from "../../utils/constants";
import { useOutsideAlerter } from "../../utils/outsideAlerter";

const CurrencySelector = ({ currency, setCurrency, desktopOnly }) => {
    const [showOptions, setShowOptions] = useState(false);
    const containerRef = useRef(null);
    useOutsideAlerter(containerRef, setShowOptions);

    return (
        <div
            onClick={() => {
                setShowOptions((prevState) => !prevState);
            }}
            id="currencyContainer"
            className={desktopOnly ? "currency-container desktop-only" : "currency-container"}
            ref={containerRef}
        >
            <div className="current-currency">
                <div className="flex-center gap-1">
                    <img src={`./flags/${currency}.png`} className="selected-flag" alt="" />
                    <span className="selected-currency">{currency}</span>
                    <button>
                        <div>
                            <svg
                                width="1rem"
                                height="0.6rem"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 1L5 5L9 1"
                                    stroke="#26c0e5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
            {showOptions && (
                <div className="currency-option-container">
                    {currencies.map((item) => (
                        <button
                            key={item}
                            onClick={() => {
                                setCurrency(item);
                            }}
                            className={
                                currency === item
                                    ? `currency-option ${item} active`
                                    : `currency-option ${item}`
                            }
                        >
                            <div className="option-container">
                                <div className="currency-info">
                                    <img className="flag" src={`./flags/${item}.png`} />
                                    <span className="currency-name">{item}</span>
                                </div>
                                <span className="currency-symbol">{currencySymbols[item]}</span>
                            </div>
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};
export default CurrencySelector;
