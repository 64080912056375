import { useEffect, useState } from "react";
import CandlestickChart from "../../utils/candlestick";

const CandleStick = ({ selectedTime, item, currency }) => {
    const [graphData, setGraphData] = useState();
    const getGraphData = async (url, timeFilter) => {
        // Storing response
        const response = await fetch("https://ve2.kunato.ai/ohlc", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // /make sure to serialize your JSON body
            body: JSON.stringify({
                currency: window.qxOptions.currency,
                url: url,
                duration: timeFilter,
            }),
        });

        // Storing data in form of JSON
        const data = await response.json();
        if (data.ok) {
            let _data = data;
            _data = _data.data;
            setGraphData(_data);
        }
    };
    const ResizeFunc = (e) => {
        if (graphData?.length > 0) {
            setArticleGraph(graphData);
        }
    };

    useEffect(() => {
        if (item?.url) {
            getGraphData(item.url, selectedTime);
        }
    }, [selectedTime, item, currency]);

    useEffect(() => {
        if (graphData?.length > 0) {
            setArticleGraph(graphData);
        }
    }, [graphData]);

    useEffect(() => {
        window.addEventListener("resize", ResizeFunc);
        return () => {
            window.removeEventListener("resize", ResizeFunc);
        };
    }, []);

    const setArticleGraph = (data) => {
        const container = document.querySelector("#article-graph");
        while (container.firstChild) {
            container.removeChild(container.lastChild);
        }
        const graph = CandlestickChart(data, {
            time: (d) => new Date(d.time),
            high: (d) => d.high,
            low: (d) => d.low,
            open: (d) => d.open,
            close: (d) => d.close,
            width: container.getBoundingClientRect().width,
            height: container.getBoundingClientRect().height * 0.99,
            marginTop: document.documentElement.clientWidth > 786 ? 20 : 30,
            marginRight: document.documentElement.clientWidth > 786 ? 30 : 10,
            marginBottom: document.documentElement.clientWidth > 786 ? 30 : 30,
            marginLeft: document.documentElement.clientWidth > 786 ? 32 : 30,
            selectedTime,
        });
        container.append(graph);
    };

    return <div id="article-graph"></div>;
};
export default CandleStick;
