import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import ErrorState from "../components/Error";
import Auth from "../components/Auth";
import Main from "../components/Main";
import "odometer/themes/odometer-theme-minimal.css";

const MainPage = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("accessToken")) {
            let decoded;
            try {
                decoded = jwtDecode(localStorage.getItem("accessToken"));
            } catch (err) {
                console.log(err);
            }
            const index = decoded?.services?.findIndex(
                (item) => item.identifier === "kunato.kunato.stage"
            );
            if (index >= 0) {
                setLoggedIn(true);
            } else {
                localStorage.clear();
                setLoggedIn(false);
            }
        } else {
            setLoggedIn(false);
        }
    }, [loggedIn]);

    return (
        <div style={{ flex: 1 }}>
            {error ? (
                <ErrorState />
            ) : loggedIn ? (
                <Main setError={setError} />
            ) : (
                <Auth setLoggedIn={setLoggedIn} />
            )}
        </div>
    );
};
export default MainPage;
