import { useEffect, useRef, useState } from "react";

import Odometer from "odometer";

import { changingValues, initialValueDifference } from "../../utils/constants";
import MainSection from "./main";
import MobileActive from "./mobileActive";
import MobileIntro from "./mobileIntro";

const Main = ({ setError }) => {
    const [stats, setStats] = useState({
        contentsPriced: 0,
        usersEngaged: 0,
        overallContentWorth: 0,
        pageViews: 0,
        graphInteractions: 0,
        readTime: 0,
        avgPriceForcast: 0,
        maximumComparisions: 0,
        priceChangeRate: 0,
        avgInitalPriceInDollars: 0,
        highestContentPricedInDollars: 0,
        contentComparisionsPerSeconds: 0,
        contentConsumedValue: 0,
        accuracy: 0,
    });
    const [graphData, setGraph] = useState({
        contentPriced: [],
        usersEngaged: [],
        consumedContent: [],
    });
    const [currency, setCurrency] = useState("USD");
    const [formatOption, setFormatOption] = useState("en-US");
    const [displayedCurrency, setDisplayedCurrency] = useState("USD");
    const OdometerObject = useRef({});
    const controller = useRef(null);
    // let initialized = useRef(null);

    const setGraphData = (result) => {
        let newData = { contentPriced: [], usersEngaged: [], consumedContent: [] };

        for (let i = 0; i < result.data.weeklyContentCrawled.length; i++) {
            newData.usersEngaged.push({
                type: "Users Engaged",
                date: new Date(result.data.weeklyContentCrawled[i].weeks_sunday).toISOString(),
                value: result.data.weeklyUsers[i].growth,
            });
            newData.contentPriced.push({
                type: "Content Priced",
                date: new Date(result.data.weeklyContentCrawled[i].weeks_sunday).toISOString(),
                value: result.data.weeklyContentCrawled[i].growth,
            });
            newData.consumedContent.push({
                type: "Consumed Content Value",
                date: new Date(result.data.weeklyContentCrawled[i].weeks_sunday).toISOString(),
                value: result.data.weeklyConsumedContentValue[i].growth,
            });
        }
        setGraph(newData);
    };

    const getData = async (setGraph, forceUpdate) => {
        controller.current = new AbortController();
        await fetch(`https://a.kunato.io/tech_data?currency=${currency}`, {
            signal: controller.current.signal,
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.data.currency === currency) {
                    setStats(() => {
                        return forceUpdate ? { ...result.data, forced: true } : result.data;
                    });

                    if (setGraph) {
                        setGraphData(result);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const updateCurrency = async (currency, skipfetch) => {
        if (currency === displayedCurrency) {
            return;
        }
        if (!skipfetch) {
            await getData(true, true);
        }

        setTimeout(() => {
            setDisplayedCurrency(currency);
        }, 100);

        UpdateFormat(currency);

        if (currency === "INR" || currency === "USD") {
            window.qxOptions.currency = currency.toLowerCase();
        }
    };

    const InitializeStats = async () => {
        try {
            const res = await fetch(`https://a.kunato.io/tech_data`);
            const result = await res.json();
            setStats(() => {
                let initialData = {};
                changingValues.map(
                    (item) => (initialData[item] = result.data[item] - initialValueDifference[item])
                );
                initialData.initial = true;
                return initialData;
            });
            setTimeout(() => {
                setStats({ ...result.data, forced: true });
            }, 1200);
            setGraphData(result);
            setCurrency(result.data.currency);
            // await updateCurrency(result.data.currency, true);
        } catch (err) {
            console.log(err);
            setError(true);
        }
    };
    const UpdateFormat = (currency) => {
        changingValues.map((item) => {
            if (
                item !== "accuracy" &&
                item !== "priceChangeRate" &&
                item !== "highestContentPricedInDollars" &&
                item !== "contentComparisionsPerSeconds" &&
                OdometerObject.current[item]
            ) {
                OdometerObject.current[item].options.format =
                    currency === "INR" ? "dd,dd,dd,dd,dd,dd,ddd" : ",ddd";
            }
        });
        setFormatOption(currency === "INR" ? "en-IN" : "en-US");
    };
    const addOdometerClass = () => {
        changingValues.map((item) => {
            let elm = document.getElementById(`${item}`);
            if (elm) {
                if (item === "accuracy") {
                    OdometerObject.current[item] = new Odometer({
                        el: elm,
                        value: elm.innerText,
                        format: "(,ddd).dddddddddddddd",
                    });
                } else if (
                    item === "priceChangeRate" ||
                    item === "avgInitalPriceInDollars" ||
                    item === "avgPriceForcast"
                ) {
                    OdometerObject.current[item] = new Odometer({
                        el: elm,
                        value: parseFloat(elm.innerText.replaceAll(",", "")).toFixed(2),
                        format: "(,ddd).dd",
                    });
                } else if (item === "highestContentPricedInDollars") {
                    OdometerObject.current[item] = new Odometer({
                        el: elm,
                        value: parseFloat(elm.innerText.replaceAll(",", "")).toFixed(2),
                        format: "(,ddd).dddd",
                    });
                } else {
                    OdometerObject.current[item] = new Odometer({
                        el: elm,
                        value: parseInt(elm.innerText.replaceAll(",", "")),
                        format: currency === "INR" ? "dd,dd,dd,dd,dd,dd,ddd" : ",ddd",
                    });
                }
            }
        });
    };
    useEffect(() => {
        controller.current?.abort();
        if (!stats.initial) {
            updateCurrency(currency);
        } else {
            updateCurrency(currency, true);
        }
    }, [currency]);

    useEffect(() => {
        InitializeStats();
    }, []);
    useEffect(() => {
        if (stats.initial) {
            setTimeout(() => {
                addOdometerClass();
            }, 500);
        }
        let dataTimeout = setTimeout(() => {
            getData(false);
        }, 6500);
        return () => {
            clearTimeout(dataTimeout);
        };
    }, [stats]);

    return (
        <div className="main-container">
            <div className="old-container">
                <MobileIntro />
                <MobileActive currency={currency} setCurrency={setCurrency} />
                <MainSection
                    currency={currency}
                    setCurrency={setCurrency}
                    displayedCurrency={displayedCurrency}
                    setDisplayedCurrency={setDisplayedCurrency}
                    setError={setError}
                    graphData={graphData}
                    formatOption={formatOption}
                    stats={stats}
                    addOdometerClass={addOdometerClass}
                />
            </div>
        </div>
    );
};
export default Main;
