import { useState } from "react";
import CurrencySelector from "./currencySelector";
import PricingEngine from "./pricingEngine";
import ArticleListItem from "./articleListItem";

const RightSection = ({
    currency,
    setCurrency,
    stats,
    statsVersion,
    setStatsVersion,
    latestArticles,
    selectedArticle,
    setSelectedArticle,
}) => {
    return (
        <div className="right-section">
            <CurrencySelector currency={currency} setCurrency={setCurrency} desktopOnly={true} />
            <div className="right-container">
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div className="mission-section">
                        <div
                            className="desktop-only"
                            style={{
                                alignItems: "flex-end",
                                marginBottom: "1.5rem",
                                justifyContent: "start",
                            }}
                        >
                            <div>
                                <div
                                    className="logo-img"
                                    style={{ display: "flex", justifyContent: "center" }}
                                >
                                    <div
                                        className="login-page-logo"
                                        style={{ position: "relative", width: "fit-content" }}
                                    >
                                        <svg
                                            height="1em"
                                            viewBox="0 0 246 39"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M55.9968 10.327V24.6391C55.9968 28.8887 52.5262 32.308 48.3223 32.308C44.0696 32.308 40.6479 28.8887 40.6479 24.6391V10.327H34V24.6391C34 32.5522 40.4035 38.9512 48.3223 38.9512C56.1923 38.9512 62.6447 32.5522 62.6447 24.6391V10.327H55.9968Z"
                                                fill="#fff"
                                            />
                                            <path
                                                d="M82.7866 10.327C74.8678 10.327 68.4643 16.7748 68.4643 24.6391V38.9512H75.1122V24.6391C75.1122 20.4383 78.5339 16.9702 82.7866 16.9702C86.9904 16.9702 90.461 20.4383 90.461 24.6391V38.9512H97.1089V24.6391C97.1089 16.7748 90.6566 10.327 82.7866 10.327Z"
                                                fill="#fff"
                                            />
                                            <path
                                                d="M116.568 10.327C108.649 10.327 102.245 16.7748 102.245 24.6391C102.245 32.5522 108.649 38.9512 116.568 38.9512H117.448V32.308H116.568C112.315 32.308 108.893 28.8887 108.893 24.6391C108.893 20.4383 112.315 16.9702 116.568 16.9702H124.242V38.9512H130.89V10.327H116.568Z"
                                                fill="#fff"
                                            />
                                            <path
                                                d="M144.776 10.327V3H138.128V38.9512H144.776V16.9702H152.108V10.327H144.776Z"
                                                fill="#fff"
                                            />
                                            <path
                                                d="M169.507 10.327C161.588 10.327 155.185 16.7748 155.185 24.6391C155.185 32.5522 161.588 38.9512 169.507 38.9512C177.377 38.9512 183.829 32.5522 183.829 24.6391C183.829 16.7748 177.377 10.327 169.507 10.327ZM169.507 32.308C165.254 32.308 161.832 28.8887 161.832 24.6391C161.832 20.4383 165.254 16.9702 169.507 16.9702C173.711 16.9702 177.181 20.4383 177.181 24.6391C177.181 28.8887 173.711 32.308 169.507 32.308Z"
                                                fill="#fff"
                                            />
                                            <path
                                                d="M191.07 39C192.879 39 194.394 37.4858 194.394 35.6784C194.394 33.8223 192.879 32.3569 191.07 32.3569C189.213 32.3569 187.746 33.8223 187.746 35.6784C187.746 37.4858 189.213 39 191.07 39Z"
                                                fill="#fff"
                                            />
                                            <path
                                                d="M219.047 3H212.545L197.881 35.483L197.832 38.9512H203.6L215.82 11.9878L227.992 38.9512H233.809V35.825L219.047 3Z"
                                                fill="#fff"
                                            />
                                            <path
                                                d="M245.07 38.9512V10.327H238.422V38.9512H245.07Z"
                                                fill="#fff"
                                            />
                                            <rect width="7.46809" height="39" fill="#fff" />
                                            <path
                                                d="M23.4415 0.415039H30.2872V3.73419L19.0851 18.2555C19.0851 18.2555 18.2104 19.056 18.2553 19.7076C18.2955 20.2904 19.0851 20.9523 19.0851 20.9523L30.2872 35.4735V38.7927H23.4415L11.8245 23.2342C11.8245 23.2342 10.7872 21.1431 10.7872 19.7076C10.7872 18.272 11.8245 16.181 11.8245 16.181L23.4415 0.415039Z"
                                                fill="#fff"
                                            />
                                        </svg>
                                        <div
                                            style={{
                                                fontWeight: 900,
                                                top: 0,
                                                right: "4.6em",
                                                opacity: 0.65,
                                                color: "#fff",
                                                fontSize: "1rem",
                                                position: "absolute",
                                            }}
                                        >
                                            TM
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span>
                                A quantitative deep learning system that predicts, assigns and
                                updates the value of any digital content in real-time without the
                                need for human intervention.
                            </span>
                        </div>
                    </div>
                    <div className="stats-options-container">
                        <div
                            onClick={() => {
                                setStatsVersion("pricing-engine");
                            }}
                            className={
                                statsVersion === "pricing-engine"
                                    ? "stats-options left active"
                                    : "stats-options left"
                            }
                        >
                            PRICING ENGINE
                        </div>
                        <div
                            onClick={() => {
                                setStatsVersion("latest-articles");
                            }}
                            className={
                                statsVersion === "latest-articles"
                                    ? "stats-options right active"
                                    : "stats-options right"
                            }
                        >
                            ARTICLES
                        </div>
                    </div>
                    <div className="stats">
                        {statsVersion === "pricing-engine" && (
                            <PricingEngine accuracy={stats.accuracy} />
                        )}
                        {statsVersion === "latest-articles" && (
                            <div className="latest-articles">
                                <div className="article-list">
                                    {latestArticles.map((item, i) => (
                                        <ArticleListItem
                                            key={item.url}
                                            item={item}
                                            selectedArticle={selectedArticle}
                                            setSelectedArticle={setSelectedArticle}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="copyright">© {new Date().getFullYear()} Kunato All rights reserved</div>
        </div>
    );
};
export default RightSection;
