import { useEffect, useRef, useState } from "react";

import Odometer from "odometer";

import TnC from "./tnc";
import AccessForm from "./accessForm";
import LoginForm from "./loginForm";

const Auth = ({ setLoggedIn }) => {
    const [altMethod, setAltMethod] = useState(false);
    const [showTnc, setShowTnc] = useState(false);
    const [usersEngaged, setUsersEngaged] = useState(90485048);
    const [accessForm, setAccessForm] = useState({
        fullname: "",
        countryCode: "91",
        phone: "",
        email: "",
        company: "",
        agreed: false,
    });

    const odometerElm = useRef(null);

    let timeout;

    const updateLoginPageUserCount = async () => {
        if (document.querySelector(".login-page-container")) {
            await fetch(`https://a.kunato.io/tech_data`)
                .then((res) => res.json())
                .then((result) => {
                    setUsersEngaged(result.data.usersEngaged);
                })
                .catch((err) => {
                    console.log(err);
                });

            timeout = setTimeout(() => {
                updateLoginPageUserCount();
            }, 5000);
        }
    };
    useEffect(() => {
        updateLoginPageUserCount();
    }, []);

    useEffect(() => {
        new Odometer({
            el: odometerElm?.current,
            value: parseInt(odometerElm.current?.innerText.replaceAll(",", "")),
            format: window?.qxOptions?.currency === "INR" ? "dd,dd,dd,dd,dd,dd,ddd" : ",ddd",
        });
    }, []);

    return (
        <div className="login-page-container">
            <div className="login-page-left min-h-[80vh] w-full relative">
                <div className="absolute lg:w-full h-full z-[0] left-4 right-4 lg:left-16 lg:right-[initial] rounded-xl overflow-hidden">
                    <div className="absolute h-full w-full z-[1] bg-[rgba(0,0,0,0.6)] flex flex-col justify-evenly items-center">
                        <div className="flex flex-col gap-2">
                            <div
                                className="login-page-logo"
                                style={{ position: "relative", width: "fit-content" }}
                            >
                                <svg
                                    height="1em"
                                    viewBox="0 0 246 39"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M55.9968 10.327V24.6391C55.9968 28.8887 52.5262 32.308 48.3223 32.308C44.0696 32.308 40.6479 28.8887 40.6479 24.6391V10.327H34V24.6391C34 32.5522 40.4035 38.9512 48.3223 38.9512C56.1923 38.9512 62.6447 32.5522 62.6447 24.6391V10.327H55.9968Z"
                                        fill="#0085FF"
                                    />
                                    <path
                                        d="M82.7866 10.327C74.8678 10.327 68.4643 16.7748 68.4643 24.6391V38.9512H75.1122V24.6391C75.1122 20.4383 78.5339 16.9702 82.7866 16.9702C86.9904 16.9702 90.461 20.4383 90.461 24.6391V38.9512H97.1089V24.6391C97.1089 16.7748 90.6566 10.327 82.7866 10.327Z"
                                        fill="#0085FF"
                                    />
                                    <path
                                        d="M116.568 10.327C108.649 10.327 102.245 16.7748 102.245 24.6391C102.245 32.5522 108.649 38.9512 116.568 38.9512H117.448V32.308H116.568C112.315 32.308 108.893 28.8887 108.893 24.6391C108.893 20.4383 112.315 16.9702 116.568 16.9702H124.242V38.9512H130.89V10.327H116.568Z"
                                        fill="#0085FF"
                                    />
                                    <path
                                        d="M144.776 10.327V3H138.128V38.9512H144.776V16.9702H152.108V10.327H144.776Z"
                                        fill="#0085FF"
                                    />
                                    <path
                                        d="M169.507 10.327C161.588 10.327 155.185 16.7748 155.185 24.6391C155.185 32.5522 161.588 38.9512 169.507 38.9512C177.377 38.9512 183.829 32.5522 183.829 24.6391C183.829 16.7748 177.377 10.327 169.507 10.327ZM169.507 32.308C165.254 32.308 161.832 28.8887 161.832 24.6391C161.832 20.4383 165.254 16.9702 169.507 16.9702C173.711 16.9702 177.181 20.4383 177.181 24.6391C177.181 28.8887 173.711 32.308 169.507 32.308Z"
                                        fill="#0085FF"
                                    />
                                    <path
                                        d="M191.07 39C192.879 39 194.394 37.4858 194.394 35.6784C194.394 33.8223 192.879 32.3569 191.07 32.3569C189.213 32.3569 187.746 33.8223 187.746 35.6784C187.746 37.4858 189.213 39 191.07 39Z"
                                        fill="#0085FF"
                                    />
                                    <path
                                        d="M219.047 3H212.545L197.881 35.483L197.832 38.9512H203.6L215.82 11.9878L227.992 38.9512H233.809V35.825L219.047 3Z"
                                        fill="#0085FF"
                                    />
                                    <path
                                        d="M245.07 38.9512V10.327H238.422V38.9512H245.07Z"
                                        fill="#0085FF"
                                    />
                                    <rect width="7.46809" height="39" fill="#0085FF" />
                                    <path
                                        d="M23.4415 0.415039H30.2872V3.73419L19.0851 18.2555C19.0851 18.2555 18.2104 19.056 18.2553 19.7076C18.2955 20.2904 19.0851 20.9523 19.0851 20.9523L30.2872 35.4735V38.7927H23.4415L11.8245 23.2342C11.8245 23.2342 10.7872 21.1431 10.7872 19.7076C10.7872 18.272 11.8245 16.181 11.8245 16.181L23.4415 0.415039Z"
                                        fill="#0085FF"
                                    />
                                </svg>
                                <div
                                    style={{
                                        fontWeight: 900,
                                        top: 0,
                                        right: "4.6em",
                                        opacity: 0.65,
                                        color: "#0085FF",
                                        fontSize: "1rem",
                                        position: "absolute",
                                    }}
                                >
                                    TM
                                </div>
                            </div>
                            <div className="login-page-desc">
                                A quantitative deep learning system <br />
                                that predicts, assigns and updates the <br />
                                value of any digital content in real-time <br />
                                without the need for human intervention.
                            </div>
                        </div>
                        <div className="relative z-[3]">
                            <div ref={odometerElm} className="login-page-user-count odometer">
                                {usersEngaged}
                            </div>
                            <div className="login-page-user-count-label">USERS ENGAGED</div>
                        </div>
                    </div>
                    <video autoPlay muted loop className="w-full h-full object-cover">
                        <source
                            src="https://sgp1.digitaloceanspaces.com/qx-cdn/files/kunato.ai/login.mp4"
                            type="video/mp4"
                        />
                    </video>
                </div>
            </div>
            <div className="login-page-right">
                <div className="login-form-container-box">
                    {altMethod ? (
                        <AccessForm
                            setAltMethod={setAltMethod}
                            setShowTnc={setShowTnc}
                            accessForm={accessForm}
                            setAccessForm={setAccessForm}
                        />
                    ) : (
                        <LoginForm setAltMethod={setAltMethod} setLoggedIn={setLoggedIn} />
                    )}
                </div>
            </div>
            {showTnc && <TnC setShowTnc={setShowTnc} setAccessForm={setAccessForm} />}
        </div>
    );
};
export default Auth;
