import { useRef, useState } from "react";

import LeftHeader from "./leftHeader";
import LeftTable from "./leftTable";
import RightTable from "./rightTable";
import LineGraph from "./graph";
import LatestArticleStats from "./latestArticles";

const LeftSection = ({
    currency,
    stats,
    setSelectedGraph,
    selectedGraph,
    graphData,
    formatOption,
    statsVersion,
    selectedArticle,
    ScrollArticle,
}) => {
    const [hideOnMobile, setHideOnMobile] = useState("right");
    return (
        <div className="left-section">
            {statsVersion === "pricing-engine" && (
                <div className="left-stats-container">
                    <LeftHeader
                        currency={currency}
                        setSelectedGraph={setSelectedGraph}
                        stats={stats}
                    />
                    <LineGraph
                        currency={currency}
                        selectedGraph={selectedGraph}
                        formatOption={formatOption}
                        graphData={graphData}
                    />

                    <div className="mobile-selector-container">
                        <button
                            onClick={() => {
                                setHideOnMobile("right");
                            }}
                            id="selectcontent"
                            className={
                                hideOnMobile === "left"
                                    ? "mobile-selector left"
                                    : "mobile-selector left active"
                            }
                        >
                            CONTENT
                        </button>
                        <button
                            onClick={() => {
                                setHideOnMobile("left");
                            }}
                            id="selectbehavior"
                            className={
                                hideOnMobile === "right"
                                    ? "mobile-selector"
                                    : "mobile-selector active"
                            }
                        >
                            BEHAVIOR
                        </button>
                    </div>
                    <div className="tables">
                        <LeftTable
                            currency={currency}
                            hideOnMobile={hideOnMobile === "left"}
                            stats={stats}
                        />
                        <RightTable hideOnMobile={hideOnMobile === "right"} stats={stats} />
                    </div>
                </div>
            )}
            {statsVersion === "latest-articles" && (
                <LatestArticleStats
                    ScrollArticle={ScrollArticle}
                    selectedArticle={selectedArticle}
                    currency={currency}
                />
            )}
            <div className="disclaimer desktop-only">
                Disclaimer: &nbsp;
                <span>
                    Some of the data has been calculated based on complex formulas. Calculation
                    accuracy may vary between 60%-90%.
                </span>
            </div>
        </div>
    );
};
export default LeftSection;
