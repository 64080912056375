import CurrencySelector from "./currencySelector";

const MobileActive = ({ currency, setCurrency }) => {
    return (
        <div className="mobile-active-container">
            <div>
                <CurrencySelector
                    currency={currency}
                    setCurrency={setCurrency}
                    desktopOnly={false}
                />
            </div>
        </div>
    );
};

export default MobileActive;
