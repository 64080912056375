import { useEffect, useState } from "react";

import "./index.css";

const VerifyAccount = () => {
    const [status, setStatus] = useState("failure");
    const [error, setError] = useState("");

    const CreateRequest = async () => {
        try {
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });

            const response = await fetch(`https://admin.kunato.ai/api/v1/request/create-request`, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },

                body: JSON.stringify({
                    token: params.token,
                }),
            });
            const data = await response.json();
            if (data.ok) {
                setStatus("success");
            } else {
                setStatus("failure");
                setError(data?.error?.message);
            }
        } catch (e) {
            console.log(e);

            setError("Some unknown error occurred , Please try again later.");
        }
    };
    useEffect(() => {
        CreateRequest();
    }, []);

    return (
        <div className="popup-container">
            {status === "success" && (
                <div id="success" className="verify-popup-box">
                    <div>
                        <svg
                            height="6rem"
                            viewBox="0 0 65 65"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M32.5 5.41675C17.55 5.41675 5.41663 17.5501 5.41663 32.5001C5.41663 47.4501 17.55 59.5834 32.5 59.5834C47.45 59.5834 59.5833 47.4501 59.5833 32.5001C59.5833 17.5501 47.45 5.41675 32.5 5.41675ZM32.5 54.1667C20.5562 54.1667 10.8333 44.4438 10.8333 32.5001C10.8333 20.5563 20.5562 10.8334 32.5 10.8334C44.4437 10.8334 54.1666 20.5563 54.1666 32.5001C54.1666 44.4438 44.4437 54.1667 32.5 54.1667ZM44.9312 20.5292L27.0833 38.3772L20.0687 31.3897L16.25 35.2084L27.0833 46.0417L48.75 24.3751L44.9312 20.5292Z"
                                fill="#25CA83"
                            />
                        </svg>
                    </div>
                    <div
                        style={{
                            color: "#25ca83",
                            fontSize: "2rem",
                            fontWeight: 600,
                            marginTop: "1.5rem",
                            textalign: "center",
                        }}
                    >
                        Verification Completed
                    </div>
                    <div
                        style={{
                            fontWeight: 300,
                            fontSize: "1.25rem",
                            textAlign: "center",
                            marginTop: "1.5rem",
                            lineHeight: 1.75,
                            color: "#bcbcbc",
                        }}
                    >
                        Thank you for applying access request , We will get back to you within 24-48
                        hours with the status of your request.
                    </div>
                </div>
            )}
            {status === "failure" && (
                <div id="failure" className="verify-popup-box">
                    <div>
                        <svg
                            height="4.5rem"
                            viewBox="0 0 46 46"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M23 46C35.7029 46 46 35.7029 46 23C46 10.2971 35.7029 0 23 0C10.2971 0 0 10.2971 0 23C0 35.7029 10.2971 46 23 46ZM19.55 34.4793C19.55 32.4852 21.0657 31.05 22.9793 31.05C24.9343 31.05 26.45 32.4852 26.45 34.4793C26.45 36.4734 24.9343 37.95 22.9793 37.95C21.0657 37.95 19.55 36.4734 19.55 34.4793ZM21.0956 9.2C20.9404 9.20016 20.7869 9.23173 20.6442 9.2928C20.5016 9.35386 20.3727 9.44317 20.2655 9.55535C20.1583 9.66752 20.0749 9.80023 20.0203 9.9455C19.9657 10.0908 19.9411 10.2456 19.9479 10.4006L20.6517 26.5006C20.6647 26.7967 20.7916 27.0764 21.0059 27.2813C21.2202 27.4861 21.5053 27.6003 21.8017 27.6H24.2006C24.497 27.6003 24.7821 27.4861 24.9964 27.2813C25.2107 27.0764 25.3376 26.7967 25.3506 26.5006L26.0521 10.4006C26.0589 10.2454 26.0342 10.0904 25.9795 9.94496C25.9248 9.79954 25.8412 9.66672 25.7337 9.55451C25.6262 9.44231 25.4971 9.35305 25.3542 9.29212C25.2113 9.23119 25.0575 9.19985 24.9021 9.2H21.0956Z"
                                fill="#F34860"
                            />
                        </svg>
                    </div>
                    <div
                        style={{
                            color: "#f34860",
                            fontSize: "2rem",
                            fontWeight: 600,
                            marginTop: "3rem",
                            textAlign: "center",
                        }}
                    >
                        Verification Rejected
                    </div>
                    <div
                        id="error-message"
                        style={{
                            fontWeight: 300,
                            fontSize: "1.25rem",
                            textAlign: "center",
                            marginTop: "3rem",
                            lineHeight: 1.75,
                            color: "#bcbcbc",
                        }}
                    >
                        {error}
                    </div>
                </div>
            )}
        </div>
    );
};

export default VerifyAccount;
