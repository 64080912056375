const MobileIntro = () => {
    return (
        <div className="mobile-intro">
            <div className="brand">
                <div className="logo-img">
                    <img src="/newLogo.png" alt="" />
                </div>

                <div className="brand-desc">
                    A quantitative deep learning system that predicts, assigns and updates the value
                    of any digital content in real-time without the need for human intervention.
                </div>
            </div>
        </div>
    );
};
export default MobileIntro;
