import ArticleStats from "./articleStats";

const LatestArticleStats = ({ ScrollArticle, selectedArticle }) => {
    return (
        <div className="left-article-stats-container">
            <ArticleStats
                key={selectedArticle?.url}
                item={selectedArticle}
                ScrollArticle={ScrollArticle}
            />
        </div>
    );
};
export default LatestArticleStats;
