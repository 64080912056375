import "./App.css";
import { RouterProvider } from "react-router-dom";
import router from "./components/router";
import mixpanel from "mixpanel-browser";

function App() {
    mixpanel.init("0e557812af89b112a7de924a6b90d26d");

    return (
        <div className="App">
            <video autoPlay muted loop className="desktop-bg-video">
                <source src="/numbersbg.mp4" type="video/mp4" />
            </video>
            <div className="desktop-bg-overlay"></div>
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
