import { useEffect, useRef, useState } from "react";
import LineChart from "../../utils/linechart";

const LineGraph = ({ currency, selectedGraph, formatOption, graphData }) => {
    const [daysPassed, setDays] = useState();
    const graphRef = useRef(null);

    const setDaysPassed = () => {
        const target = `${Math.ceil((new Date() - new Date(2022, 1, 1)) / (1000 * 60 * 60 * 24))}`;
        setDays(target);
    };

    useEffect(() => {
        setDaysPassed();
    }, []);
    const AddChart = (data) => {
        const GraphContainer = graphRef.current;

        while (GraphContainer.firstChild) {
            GraphContainer.removeChild(GraphContainer.lastChild);
        }

        const chart = LineChart(data, {
            x: (d) => new Date(d.date),
            y: (d) => d.value,
            z: (d) => d.type,
            width: document.querySelector("#graph").getBoundingClientRect().width,
            height: document.querySelector("#graph").getBoundingClientRect().height * 0.99,
            currency,
            formatOption,
        });

        GraphContainer.append(chart);
    };
    const ResizeFunc = (e) => {
        const selectedData =
            selectedGraph === "users engaged"
                ? graphData.usersEngaged
                : selectedGraph === "content priced"
                ? graphData.contentPriced
                : selectedGraph === "consumed content"
                ? graphData.consumedContent
                : null;
        if (selectedData.length > 0) {
            AddChart(selectedData);
        }
    };

    useEffect(() => {
        AddChart(
            selectedGraph === "users engaged"
                ? graphData.usersEngaged
                : selectedGraph === "content priced"
                ? graphData.contentPriced
                : selectedGraph === "consumed content"
                ? graphData.consumedContent
                : null
        );
    }, [graphData, currency, selectedGraph]);
    useEffect(() => {
        window.addEventListener("resize", ResizeFunc);
        return () => {
            window.removeEventListener("resize", ResizeFunc);
        };
    }, []);
    useEffect(() => {
        document.querySelector(".graphOuter").addEventListener("wheel", (event) => {
            event.preventDefault();
        });
    }, []);

    return (
        <div className="graphOuter">
            <div className="graphContainer">
                <div id="graph" ref={graphRef}></div>
            </div>
            <div className="active-since">
                <span style={{ color: "#989898" }}>ACTIVE SINCE : </span>
                <span id="daycount">{daysPassed}</span> DAYS
            </div>
        </div>
    );
};
export default LineGraph;
