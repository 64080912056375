import * as d3 from "d3";

function CandlestickChart(
    data,
    {
        time = (d) => d.time, // given d in data, returns the (temporal) x-value
        open = (d) => d.open, // given d in data, returns a (quantitative) y-value
        close = (d) => d.close, // given d in data, returns a (quantitative) y-value
        high = (d) => d.high, // given d in data, returns a (quantitative) y-value
        low = (d) => d.low, // given d in data, returns a (quantitative) y-value
        title, // given d in data, returns the title text
        marginTop = 20, // top margin, in pixels
        marginRight = 30, // right margin, in pixels
        marginBottom = 30, // bottom margin, in pixels
        marginLeft = 40, // left margin, in pixels
        width = 640, // outer width, in pixels
        height = 400, // outer height, in pixels
        xType = d3.scaleUtc,
        xDomain, // array of x-values (defaults to every weekday)
        xRange = [marginLeft, width - marginRight], // [left, right]
        xPadding = 0.2,
        xTicks, // array of x-values to label (defaults to every other Monday)
        yType = d3.scaleLinear, // type of y-scale
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        xFormat = "", // a format specifier for the date on the x-axis
        yFormat = "~f", // a format specifier for the value on the y-axis
        yLabel, // a label for the y-axis
        stroke = "currentColor", // stroke color for the daily rule
        strokeLinecap, // stroke line cap for the rules
        colors = ["#25ca83", "#999999", "#f34860"], // [up, no change, down]
        selectedTime,
    } = {}
) {
    // Compute values.
    const X = d3.map(data, time);
    const Yo = d3.map(data, open);
    const Yc = d3.map(data, close);
    const Yh = d3.map(data, high);
    const Yl = d3.map(data, low);
    const I = d3.range(X.length);

    // Compute default domains and ticks.
    if (xDomain === undefined)
        xDomain = [
            d3.min(X, (d) => {
                let date = new Date(d);
                return date;
            }),
            d3.min(X, (d) => {
                let date = new Date(d).getTime();
                if (selectedTime === "6h") {
                    return new Date(date + 21600000);
                } else if (selectedTime === "1d") {
                    return new Date(date + 86400000);
                } else {
                    return new Date(date + 2592000000);
                }
            }),
        ];
    const difference = new Date(xDomain[1]).getTime() - new Date(xDomain[0]).getTime();
    xDomain = [
        new Date(new Date(xDomain[0]).getTime() - Math.trunc(0.1 * difference)),
        new Date(new Date(xDomain[1]).getTime() + Math.trunc(0.1 * difference)),
    ];
    if (yDomain === undefined)
        yDomain = [
            d3.min(Yl, (d) => {
                return d * 0.8;
            }),
            d3.max(Yh, (d) => {
                return d * 1.2;
            }),
        ];

    const candlewidth = (width / 30) * 0.5;

    const xScale = xType(xDomain, [xRange[0], xRange[1]]);
    const yScale = yType(yDomain, yRange);
    const xAxis =
        document.documentElement.clientWidth < 786
            ? d3.axisBottom(xScale).ticks(Math.min(6)).tickSizeOuter(0)
            : d3.axisBottom(xScale).tickSizeOuter(0);

    const yAxis = d3
        .axisLeft(yScale)
        .ticks(height / 40, yFormat)
        .tickSizeOuter(0);

    const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    svg.append("g")
        .attr("transform", `translate(0,${height - marginBottom})`)
        .attr("color", "#434343")
        .call(xAxis);

    svg.append("g")
        .attr("transform", `translate(${marginLeft},0)`)
        .attr("color", "#434343")
        .call(yAxis)

        .call((g) =>
            g
                .append("text")
                .attr("x", -marginLeft)
                .attr("y", 10)
                .attr("fill", "currentColor")
                .attr("text-anchor", "start")
                .text(yLabel)
        );

    const g = svg
        .append("g")
        .attr("stroke", stroke)
        .attr("stroke-linecap", strokeLinecap)
        .selectAll("g")
        .data(
            I.filter((i) => {
                return data[i].type === "point";
            })
        )
        .join("g")
        .attr("transform", (i) => {
            if (data[i].type === "point") {
                return `translate(${xScale(X[i])},0)`;
            } else {
                return `translate(${
                    (xScale(X[i - 1]) + xScale(X[i + 1])) / 2 - candlewidth / 2
                },0)`;
            }
        });

    g.append("line")
        .attr("y1", (i) => yScale(Yl[i]))
        .attr("y2", (i) => yScale(Yh[i]));

    g.append("line")
        .attr("y1", (i) => yScale(Yo[i]))
        .attr("y2", (i) => yScale(Yc[i]))
        .attr("stroke-width", d3.min([150, candlewidth]))
        .attr("stroke", (i) => colors[1 + Math.sign(Yo[i] - Yc[i])]);

    const separator = svg
        .append("g")
        .selectAll("g")
        .data(
            I.filter((i) => {
                return data[i].type === "separator";
            })
        )
        .join("g")
        .attr("transform", (i) => {
            if (data[i].type === "point") {
                return `translate(${xScale(X[i])},0)`;
            } else {
                return `translate(${
                    (xScale(X[i - 1]) + xScale(X[i + 1])) / 2 - candlewidth / 2
                },0)`;
            }
        });
    separator
        .append("svg")
        .attr("xmlns", "http://www.w3.org/2000/svg")
        .attr("fill", "#FFF")
        .attr("width", candlewidth)
        .attr("viewBox", "0 0 32.055 32.055")
        .append("g")
        .append("path")
        .attr(
            "d",
            "M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967   C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967   s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967   c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z"
        );

    if (title) g.append("title").text(title);

    return svg.node();
}
export default CandlestickChart;
