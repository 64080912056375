export const changingValues = [
    "contentsPriced",
    "usersEngaged",
    "highestContentPricedInDollars",
    "graphInteractions",
    "pageViews",
    "readTime",
    "maximumComparisions",
    "contentComparisionsPerSeconds",
    "priceChangeRate",
    "contentConsumedValue",
    "accuracy",
];
export const currencySymbols = {
    USD: "$",
    INR: "₹",
    EUR: "€",
    GBP: "£",
    JPY: "¥",
    CAD: "$",
    AUD: "$",
    AED: "د.إ",
    ILS: "₪",
    CNY: "¥",
    BRL: "R$",
    BTC: "₿",
    ETH: "Ξ",
};
export const currencies = [
    "USD",
    "INR",
    "EUR",
    "GBP",
    "JPY",
    "CAD",
    "AUD",
    "AED",
    "ILS",
    "CNY",
    "BRL",
    "BTC",
    "ETH",
];

export const times = ["6h", "1d", "lt"];

export const initialValueDifference = {
    contentsPriced: 412,
    usersEngaged: 921,
    overallContentWorth: 1132,
    pageViews: 1254,
    graphInteractions: 1427,
    readTime: 4523,
    avgPriceForcast: 2.25,
    maximumComparisions: 5000,
    priceChangeRate: 0.1,
    avgInitalPriceInDollars: 0.21,
    highestContentPricedInDollars: 1.12,
    contentComparisionsPerSeconds: 38,
    contentConsumedValue: 1132,
    accuracy: 0.00000000003111,
};
