import * as d3 from "d3";

function transition(pathId, elmId) {
    if (
        document.querySelectorAll(`#${elmId}`).length < 1 ||
        document.querySelectorAll(`#${pathId}`).length < 1
    ) {
        return;
    }
    let path = document.querySelector(`#${pathId}`);
    d3.select(`#${elmId}`)
        .attr("display", null)
        .attr(
            "transform",
            `translate(${path.getPointAtLength(path.getTotalLength() * 0.7).x},${
                path.getPointAtLength(path.getTotalLength() * 0.7).y
            })`
        )
        .transition()
        .ease(d3.easeSinInOut)
        .duration(200)
        .attr("opacity", "0.8")
        .transition()
        .ease(d3.easeSinInOut)
        .duration(800)
        .attrTween("transform", translateAlong(path))
        .on("end", () => {
            setTimeout(() => {
                d3.select(`#${elmId}`)
                    .transition()
                    .duration(400)
                    .attr("opacity", "1")
                    .transition()
                    .duration(400)
                    .attr("opacity", "0.5")
                    .transition()
                    .duration(400)
                    .attr("opacity", "1")
                    .transition()
                    .duration(400)
                    .attr("opacity", "0.5")
                    .transition()
                    .duration(400)
                    .attr("opacity", "1")
                    .transition()
                    .duration(400)
                    .attr("opacity", "0");
            }, 100);
        });
    // .attr("opacity", "0");
}

// Returns an attrTween for translating along the specified path element.
function translateAlong(path) {
    var l = path.getTotalLength();
    return function (d, i, a) {
        return function (t) {
            var p = path.getPointAtLength(t * l * 0.3 + l * 0.7);
            return "translate(" + p.x + "," + p.y + ")";
        };
    };
}

export default transition;
